/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'brightness-alt-high-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M8 3a.5.5 0 01.5.5v2a.5.5 0 01-1 0v-2A.5.5 0 018 3m8 8a.5.5 0 01-.5.5h-2a.5.5 0 010-1h2a.5.5 0 01.5.5m-13.5.5a.5.5 0 000-1h-2a.5.5 0 000 1zm11.157-6.157a.5.5 0 010 .707l-1.414 1.414a.5.5 0 11-.707-.707l1.414-1.414a.5.5 0 01.707 0m-9.9 2.121a.5.5 0 00.707-.707L3.05 5.343a.5.5 0 10-.707.707zM8 7a4 4 0 00-4 4 .5.5 0 00.5.5h7a.5.5 0 00.5-.5 4 4 0 00-4-4"/>',
    },
});
